<template>
  <div class="setNameContainer">
      <van-nav-bar
        title="设置昵称"
        left-text="返回"
        right-text="完成"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
      />

      <van-form >
        <van-field
          v-model ="nickname"
          show-word-limit
          autofocus
          maxlength=15
          label="昵称"
          :rules="[{ required: true, message: '昵称不能为空' }]"
        />

      </van-form>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  data() {
    return {
      nickname: this.$store.getters.getUser.nickname
    }
  },
   methods: {
    onClickLeft() {
      this.$router.replace('/Edit')
    },

    onClickRight() {
      const _this = this
      if(this.nickname){
        _this.$axios.post('/user/editInfo',{
          id: _this.$store.getters.getUser.id,
          username: _this.$store.getters.getUser.username,
          avatar: _this.$store.getters.getUser.avatar,
          nickname: _this.nickname
        }).then(
          response => {
            // console.log(response.data.data)
            const userInfo = response.data.data
            // console.log(_this.$store.getters.getUser)
            _this.$store.commit("SET_USERINFO", userInfo)
            Toast("操作成功(❁´◡`❁)")
            this.$router.replace("/Edit")
          }
        )
      }

    },
  },
}
</script>

<style scoped>

.setNameContainer{
  height: 100vh;
  background-color: white;
}

</style>